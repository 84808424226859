<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-map-marked"></i> ข้อมูลกลุ่มสถานที่ตั้ง </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <pageLocationGroup />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pageLocationGroup from './view/page.location_group';
export default {
  name: 'location_group-index',
  methods: {},
  components: {
    pageLocationGroup: pageLocationGroup
  }
}
</script>
